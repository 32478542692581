import React, {useCallback, useEffect, useState} from 'react';
import {trackEvent} from '../../utils/tracking';
import {HiChevronDown, HiChevronRight, HiOutlineXCircle} from 'react-icons/hi';
import {Select, SelectOption} from '../../core/components/select';
import _ from 'lodash';
import cx from 'classnames';

import OutsideClickHandler from 'react-outside-click-handler';
import {useQuery} from 'react-query';

import {fetchSnomedConceptSuggestions} from '../../models/snomed-concept';
import {useAxios} from '../../utils/http';
import {
  buildElasticQuery,
  SearchBox,
  newSearchBox,
  searchBoxReportOptions,
  searchBoxOperationOptions,
} from '../../models/minerva';
import {doNotAutoFill} from 'src/pages/tools/tools-page';

const maxFields = 10;

interface MinervaSearchFormProps {
  onSubmit: (query: Object) => void;
  queryState: SearchBox[];
  queryStateChange: (queryState: SearchBox[]) => void;
  autoSubmit?: boolean;
}

export type searchBoxAutofillStateType = {
  searchBoxID: string;
  expandedSnomedConcepts: string[];
};

export const MinervaSearchForm = ({
  onSubmit,
  queryStateChange,
  queryState,
  autoSubmit = false,
}: MinervaSearchFormProps) => {
  const [searchBoxAutofillState, searchBoxAutofillStateChange] =
    useState<searchBoxAutofillStateType>();
  const http = useAxios();
  useEffect(() => {
    if (autoSubmit) {
      onSubmit(buildElasticQuery(queryState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSubmit]);

  const [isTyping, isTypingChange] = useState(false);
  const [hideSynonymsList, hideSynonymsListChange] = useState(false);
  const onTypingStop = () => {
    isTypingChange(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnTypingStop = useCallback(_.debounce(onTypingStop, 1000), []);

  const currentSearchBox = _.find(queryState, {
    id: searchBoxAutofillState?.searchBoxID,
  });
  const {
    data: snomedConceptSuggestions,
    isLoading: snomedConceptSuggestionsLoading,
    isError: snomedConceptSuggestionsIsError,
    isSuccess: snomedConceptSuggestionsIsSuccess,
  } = useQuery(
    ['snomedConceptSuggestion', _.trim(currentSearchBox?.value)],
    () => fetchSnomedConceptSuggestions(http, _.trim(currentSearchBox?.value)),
    {
      enabled: !isTyping && _.trim(currentSearchBox?.value) !== '',
      staleTime: Infinity,
      onError: () => _.noop,
    }
  );

  const addField = () => {
    queryStateChange([...queryState, newSearchBox()]);
  };

  const removeField = (id: string) => {
    queryStateChange(queryState.filter(field => field.id !== id));
  };

  const mutateSnomedTerms = (
    operation: 'add' | 'remove',
    existingTerms: string[],
    term: string
  ) => {
    if (operation === 'add') {
      if (!_.includes(existingTerms, term)) {
        existingTerms = [...existingTerms, term];
      }
    } else {
      _.remove(existingTerms, el => el === term);
    }
    return existingTerms;
  };

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex flex-col gap-y-2">
        {queryState.map((searchBox, searchBoxIndex) => (
          <div key={searchBox.id} className="grid grid-cols-12 w-full gap-x-4">
            <div className="col-span-2">
              <Select
                text={
                  _.find(searchBoxReportOptions, {
                    value: searchBox.reportType,
                  })?.label || ''
                }
                value={searchBox.reportType}
                options={searchBoxReportOptions}
                onSelect={(option: SelectOption<'Report' | 'Impression'>) => {
                  const updatedQueryState = queryState.map(field =>
                    field.id === searchBox.id
                      ? {
                          ...field,
                          value: searchBox.value,
                          reportType: option.value,
                        }
                      : field
                  );

                  queryStateChange(updatedQueryState);
                }}
                contained
              />
            </div>

            <div className="col-span-2">
              <Select
                text={
                  _.find(searchBoxOperationOptions, {
                    value: searchBox.operation,
                  })?.label || ''
                }
                value={searchBox.operation}
                options={searchBoxOperationOptions}
                onSelect={(
                  option: SelectOption<
                    | 'contains substring'
                    | 'contains substring (no negations)'
                    | 'excludes substring'
                  >
                ) => {
                  const updatedQueryState = queryState.map(field =>
                    field.id === searchBox.id
                      ? {
                          ...field,
                          value: searchBox.value,
                          operation: option.value,
                        }
                      : field
                  );

                  queryStateChange(updatedQueryState);
                }}
                contained
              />
            </div>

            <div
              className={cx({
                'col-span-8': queryState.length <= 1,
                'col-span-7': queryState.length > 1,
              })}
            >
              <OutsideClickHandler
                onOutsideClick={() => {
                  if (searchBoxAutofillState?.searchBoxID === searchBox.id) {
                    searchBoxAutofillStateChange(undefined);
                  }
                }}
              >
                <input
                  type="text"
                  className="text-input w-full text-gray-700"
                  value={
                    searchBox.id === searchBoxAutofillState?.searchBoxID ||
                    _.isEmpty(searchBox.snomedTerms)
                      ? // focused or no concept selected
                        searchBox.value
                      : searchBox
                          .snomedTerms!.map(term => `"${term}"`)
                          .join(' | ') ?? ''
                  }
                  onChange={e => {
                    hideSynonymsListChange(false);
                    !isTyping && isTypingChange(true);
                    debouncedOnTypingStop();

                    queryStateChange(
                      queryState.map(field =>
                        field.id === searchBox.id
                          ? {
                              ...field,
                              value: e.target.value,
                            }
                          : field
                      )
                    );
                  }}
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      hideSynonymsListChange(true);
                      onSubmit(buildElasticQuery(queryState));
                    }
                  }}
                  placeholder={
                    searchBoxIndex === 0
                      ? 'Type a search term to search for studies from real-world medical institutions, e.g. "abdominal CTs pancreatic cancer"'
                      : 'Type additional search term'
                  }
                  key={searchBox.id}
                  onFocus={() => {
                    searchBoxAutofillStateChange({
                      searchBoxID: searchBox.id,
                      expandedSnomedConcepts: [],
                    });
                  }}
                />
                {localStorage.getItem(doNotAutoFill) !== doNotAutoFill &&
                  !hideSynonymsList && (
                    <div className={cx('relative')}>
                      <div
                        className={cx(
                          'absolute min-w-min max-h-96 overflow-y-scroll mt-2 rounded-md shadow-lg bg-white ring-1 ring-gray-900 ring-opacity-5 z-30 m-b-5 break-words w-full p-6 space-y-4',
                          {
                            hidden:
                              searchBoxAutofillState?.searchBoxID !==
                                searchBox.id ||
                              (_.isEmpty(searchBox.snomedTerms) &&
                                (_.trim(searchBox.value) === '' ||
                                  snomedConceptSuggestionsIsError)),
                          }
                        )}
                      >
                        {!_.isEmpty(_.trim(searchBox.value)) && (
                          <div>
                            {(snomedConceptSuggestionsLoading ||
                              (!snomedConceptSuggestionsIsSuccess &&
                                isTyping)) && (
                              <span className="text-gray-500">
                                Looking for suggestions...
                              </span>
                            )}
                            {snomedConceptSuggestions?.length === 0 && (
                              <span className="text-gray-500">
                                No suggestions for{' '}
                                <span className="italic">
                                  &quot;{searchBox.value}&quot;
                                </span>
                              </span>
                            )}
                            {snomedConceptSuggestions?.map(
                              (snomedConcept, snomedConceptIndex) => {
                                const isExpanded = _.includes(
                                  searchBoxAutofillState?.expandedSnomedConcepts,
                                  `${snomedConcept.conceptId}_${snomedConcept.term}`
                                );

                                return (
                                  <div key={snomedConceptIndex}>
                                    {isExpanded ? (
                                      <button
                                        className="align-middle text-lg text-gray-500"
                                        onClick={() => {
                                          const expandedSnomedConcepts =
                                            searchBoxAutofillState?.expandedSnomedConcepts ||
                                            [];
                                          _.remove(
                                            expandedSnomedConcepts,
                                            concept =>
                                              concept ===
                                              `${snomedConcept.conceptId}_${snomedConcept.term}`
                                          );
                                          searchBoxAutofillStateChange({
                                            searchBoxID: searchBox.id,
                                            expandedSnomedConcepts: [
                                              ...expandedSnomedConcepts,
                                            ],
                                          });
                                        }}
                                      >
                                        <HiChevronDown />
                                      </button>
                                    ) : (
                                      <button
                                        className="align-middle text-lg text-gray-500"
                                        onClick={() =>
                                          searchBoxAutofillStateChange({
                                            searchBoxID: searchBox.id,
                                            expandedSnomedConcepts: [
                                              ...(searchBoxAutofillState?.expandedSnomedConcepts ||
                                                []),
                                              `${snomedConcept.conceptId}_${snomedConcept.term}`,
                                            ],
                                          })
                                        }
                                      >
                                        <HiChevronRight />
                                      </button>
                                    )}

                                    <input
                                      type="checkbox"
                                      className="checkbox-input"
                                      id={`snomed_term_${searchBox.id}_${snomedConcept.conceptId}_${snomedConcept.term}`}
                                      checked={_.includes(
                                        searchBox.snomedTerms,
                                        snomedConcept.term
                                      )}
                                      onChange={e => {
                                        const newSnomedTags = mutateSnomedTerms(
                                          e.target.checked ? 'add' : 'remove',
                                          searchBox.snomedTerms || [],
                                          snomedConcept.term
                                        );
                                        // eslint-disable-next-line security/detect-object-injection
                                        queryState[searchBoxIndex].snomedTerms =
                                          newSnomedTags;

                                        queryStateChange(
                                          _.cloneDeep(queryState)
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor={`snomed_term_${searchBox.id}_${snomedConcept.conceptId}_${snomedConcept.term}`}
                                      className="ml-2"
                                    >
                                      {snomedConcept.term}
                                    </label>

                                    {isExpanded && (
                                      <div className="ml-8 space-y-2 my-2">
                                        {!_.isEmpty(snomedConcept.synonyms) && (
                                          <div>
                                            <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                                              {snomedConcept.synonyms.length ===
                                              1
                                                ? 'Snomed Synonym'
                                                : 'Snomed Synonyms'}
                                            </div>
                                            {snomedConcept.synonyms?.map(
                                              (
                                                synonymConcept,
                                                synonymConceptIndex
                                              ) => (
                                                <div key={synonymConceptIndex}>
                                                  <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    id={`checkbox_snomed_synonym_${searchBox.id}_${snomedConcept.conceptId}_${snomedConcept.term}_${synonymConcept.term}`}
                                                    checked={_.includes(
                                                      searchBox.snomedTerms,
                                                      synonymConcept.term
                                                    )}
                                                    onChange={e => {
                                                      const newSnomedTags =
                                                        mutateSnomedTerms(
                                                          e.target.checked
                                                            ? 'add'
                                                            : 'remove',
                                                          searchBox.snomedTerms ||
                                                            [],
                                                          synonymConcept.term
                                                        );
                                                      // eslint-disable-next-line security/detect-object-injection
                                                      queryState[
                                                        searchBoxIndex
                                                      ].snomedTerms =
                                                        newSnomedTags;

                                                      queryStateChange(
                                                        _.cloneDeep(queryState)
                                                      );
                                                    }}
                                                  />
                                                  <label
                                                    htmlFor={`checkbox_snomed_synonym_${searchBox.id}_${snomedConcept.conceptId}_${snomedConcept.term}_${synonymConcept.term}`}
                                                    className="ml-2"
                                                  >
                                                    {synonymConcept.term}
                                                  </label>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}

                                        {!_.isEmpty(snomedConcept.children) && (
                                          <div>
                                            <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                                              {snomedConcept.children.length ===
                                              1
                                                ? 'Snomed Subcategory'
                                                : 'Snomed Subcategories'}
                                            </div>
                                            {snomedConcept.children?.map(
                                              (
                                                childConcept,
                                                childConceptIndex
                                              ) => (
                                                <div key={childConceptIndex}>
                                                  <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    id={`checkbox_snomed_child_${searchBox.id}_${snomedConcept.conceptId}_${snomedConcept.term}_${childConcept.term}`}
                                                    checked={_.includes(
                                                      searchBox.snomedTerms,
                                                      childConcept.term
                                                    )}
                                                    onChange={e => {
                                                      const newSnomedTags =
                                                        mutateSnomedTerms(
                                                          e.target.checked
                                                            ? 'add'
                                                            : 'remove',
                                                          searchBox.snomedTerms ||
                                                            [],
                                                          childConcept.term
                                                        );
                                                      // eslint-disable-next-line security/detect-object-injection
                                                      queryState[
                                                        searchBoxIndex
                                                      ].snomedTerms =
                                                        newSnomedTags;

                                                      queryStateChange(
                                                        _.cloneDeep(queryState)
                                                      );
                                                    }}
                                                  />
                                                  <label
                                                    htmlFor={`checkbox_snomed_child_${searchBox.id}_${snomedConcept.conceptId}_${snomedConcept.term}_${childConcept.term}`}
                                                    className="ml-2"
                                                  >
                                                    {childConcept.term}
                                                  </label>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}

                        {!_.isEmpty(searchBox.snomedTerms) && (
                          <div>
                            <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Selected
                            </div>
                            {searchBox.snomedTerms!.map(snomedTerm => (
                              <div key={snomedTerm}>
                                <input
                                  type="checkbox"
                                  className="checkbox-input"
                                  id={`checkbox_selected_snomed_term_${searchBox.id}_${snomedTerm}`}
                                  checked={_.includes(
                                    searchBox.snomedTerms,
                                    snomedTerm
                                  )}
                                  onChange={e => {
                                    const newSnomedTags = mutateSnomedTerms(
                                      e.target.checked ? 'add' : 'remove',
                                      searchBox.snomedTerms || [],
                                      snomedTerm
                                    );
                                    // eslint-disable-next-line security/detect-object-injection
                                    queryState[searchBoxIndex].snomedTerms =
                                      newSnomedTags;

                                    queryStateChange(_.cloneDeep(queryState));
                                  }}
                                />
                                <label
                                  htmlFor={`checkbox_selected_snomed_term_${searchBox.id}_${snomedTerm}`}
                                  className="ml-2"
                                >
                                  {snomedTerm}
                                </label>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
              </OutsideClickHandler>
            </div>
            <button
              onClick={() => {
                trackEvent('CLICK_REMOVE_FIELD_BTN');
                removeField(searchBox.id);
              }}
              className={
                queryState.length <= 1
                  ? 'hidden'
                  : 'flex justify-center items-center'
              }
            >
              <HiOutlineXCircle className="h-6 w-6 text-red-500 center" />
            </button>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-12 w-full gap-x-4">
        <button
          onClick={() => {
            addField();
          }}
          className={cx(
            'btn-link w-full text-left font-medium text-sm justify-center col-span-2 pl-2',
            {
              hidden: queryState.length >= maxFields,
            }
          )}
        >
          Add search term
        </button>
        <div className="col-span-9"></div>
        <div className="flex flex-row-reverse gap-x-3">
          <button
            className="btn btn-primary w-full px-5 justify-center"
            data-cy="SearchForm_searchBtn"
            id="searchBtn"
            onClick={e => {
              e.preventDefault();
              onSubmit(buildElasticQuery(queryState));
            }}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};
