/* eslint-disable security/detect-object-injection */
import React, {
  useEffect,
  useState,
  useMemo,
  Suspense,
  lazy,
  useCallback,
} from 'react';
import {Helmet} from 'react-helmet';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import {useQuery, useQueryClient, useMutation, useQueries} from 'react-query';
import {Loading} from '../../core/components/loading';
import {
  HiOutlineShieldExclamation,
  HiOutlineExclamationCircle,
} from 'react-icons/hi';
import _ from 'lodash';

import {Select, SelectOption} from '../../core/components/select';
import {
  fetchSeriesDicomPresignedUrls,
  DicomSeries,
  DicomImagePresigned,
  getDicomSeries,
  getAllFramesImageIds,
  reportCorruptedDicom,
  fetchDicomStudyInfo,
  ImageDetail,
  fetchDatasetDicomInfo,
} from '../../models/dicom-viewer';
import {Breadcrumbs} from '../../core/components/breadcrumbs';
import {fetchDataset} from '../../models/dataset';
import {fetchReport, truncateStudyID} from '../../models/report';
import {ApplicationShell} from '../../core/layout/application-shell';
import {updateDICOMPHI} from '../../models/dicom';
import {quickToast} from '../../core/components/toast';
import {ReportPHIModal} from '../../components/dicom-viewer/report-phi-modal';
import {ReportCorruptedImageModal} from '../../components/dicom-viewer/report-corrupted-image-modal';
import {
  Dimensions,
  fetchEvalonDicomocrByStudyID,
  FullEvalonResultsResp,
  getBlackoutForStudyID,
  ImageWithBlackout,
  imageWithBlackoutModelToImageWithBlackout,
} from '../../models/evalon';
import {formatOrdinalNumber} from '../../utils/strings';
import dicomParser from 'dicom-parser';
import {arrayBufferToImage, createImage} from 'src/models/web-image-loader';
import {useAxios} from 'src/utils/http';

// Lazy Load Dicom Viewer
const DicomViewer = lazy(() =>
  import('../../components/dicom-viewer/dicom-viewer').then(module => ({
    default: module.DicomViewer,
  }))
);

interface NavStudy {
  studyID: string;
  seriesID?: string;
}

interface DicomViewerNavigation {
  currentStudy: NavStudy;
  currentStudyIndex: number;
  prevStudy?: NavStudy;
  nextStudy?: NavStudy;
  length: number;
}

const getImageMetadata = (
  url: string
): Promise<{width: number; height: number}> =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.responseType = 'arraybuffer';

    xhr.open('GET', url, true);

    xhr.onload = () => {
      const imagePromise = arrayBufferToImage(xhr.response);

      imagePromise
        .then(image => {
          const imageObject = createImage(image, url);

          resolve(imageObject);
        }, reject)
        .catch(error => {
          console.error(error);
        });
    };

    xhr.send(null);

    xhr.onerror = err => {
      console.error(`Failed to load image from URL: ${url}`);
      return reject(err);
    };
  });

export const DicomViewerPage = ({
  isAdmin,
  getImageUrl,
}: {
  isAdmin?: boolean;
  getImageUrl: ({
    datasetID,
    studyID,
    seriesID,
    sopID,
  }: {
    datasetID?: number;
    studyID: string;
    seriesID?: string;
    sopID?: string;
  }) => string;
}) => {
  const api = useAxios();

  const q = useParams<{
    datasetID?: string;
    studyID: string;
    seriesID?: string;
    sopID?: string;
  }>();

  const currentStudyID = q.studyID;
  const currentSeriesID = q.seriesID;
  const currentSopID = q.sopID;
  const currentDatasetID = _.isInteger(_.toNumber(q.datasetID))
    ? _.toNumber(q.datasetID)
    : undefined;

  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // Fetch Series info for presigned url query
  const {data: studyDicomInfo, isError: studyDicomInfoIsError} = useQuery(
    ['studyDicom', currentStudyID],
    () => {
      return fetchDicomStudyInfo(api, currentStudyID!);
    },
    {
      enabled: !_.isNil(currentStudyID),
      keepPreviousData: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const series = getDicomSeries(currentStudyID!, studyDicomInfo);

  const setupStudyNavigation = (
    studyId: string,
    studies: NavStudy[],
    seriesID?: string
  ): DicomViewerNavigation => {
    const studyIndex = studies.findIndex(
      study =>
        study.studyID === studyId && (!seriesID || study.seriesID === seriesID)
    );

    let prevStudy: NavStudy | undefined;
    let nextStudy: NavStudy | undefined;
    if (studyIndex !== -1) {
      if (studyIndex > 0) {
        prevStudy = studies[studyIndex - 1];
      }
      if (studyIndex < studies.length - 1) {
        nextStudy = studies[studyIndex + 1];
      }
    }
    return {
      currentStudy: studies[studyIndex],
      currentStudyIndex: studyIndex,
      prevStudy,
      nextStudy,
      length: studies.length,
    };
  };

  const {data: studyNavigation} = useQuery<DicomViewerNavigation>(
    ['datasetDicom', currentDatasetID, currentStudyID, isAdmin],
    async () => {
      const datasetDicomInfo = await fetchDatasetDicomInfo(
        api,
        currentDatasetID!,
        isAdmin
      );
      const studies: NavStudy[] = datasetDicomInfo.studies.map(study => ({
        ...study,
        seriesNum: study.series[0].seriesNum,
        seriesID: study.series[0].seriesID,
      }));
      return setupStudyNavigation(currentStudyID!, studies);
    },
    {
      enabled: !_.isNil(currentDatasetID),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const {data: datasetWithStudies} = useQuery(
    ['dataset', currentDatasetID],
    () => fetchDataset(api, currentDatasetID!),
    {
      enabled: !_.isNil(currentDatasetID) && !isAdmin,
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const blackoutsEnabled = !_.isNil(currentStudyID) && !!isAdmin;

  const [activeBlackouts, activeBlackoutsChange] = useState<
    ImageWithBlackout[] | undefined
  >(undefined);

  const {
    data: savedBlackoutRectangles,
    isFetching: savedBlackoutRectanglesIsFetching,
  } = useQuery(
    ['savedBlackoutRectangles', currentStudyID],
    () => {
      return getBlackoutForStudyID(api, currentStudyID!).then(blackoutModels =>
        imageWithBlackoutModelToImageWithBlackout(
          blackoutModels,
          currentStudyID!
        )
      );
    },
    {
      refetchOnWindowFocus: 'always',
      enabled: blackoutsEnabled,
      keepPreviousData: false,
      staleTime: 120 * 60 * 1000, // 2 hours
      onError: (err: any) => {
        if (err?.response?.status === 500) {
          const message =
            err?.response?.data?.message ??
            'Error fetching previously drawn rectangles results';
          quickToast({title: message, icon: 'error'});
        }
        return err;
      },
    }
  );

  const navigateToImageIfChanged = useCallback(
    ({
      datasetID,
      studyID,
      seriesID,
      sopID,
    }: {
      datasetID?: number;
      studyID: string;
      seriesID?: string;
      sopID?: string;
    }) => {
      if (
        studyID &&
        !_.isEqual(
          {
            datasetID: currentDatasetID,
            studyID: currentStudyID,
            seriesID: currentSeriesID,
            sopID: currentSopID,
          },
          {
            datasetID: datasetID,
            studyID: studyID,
            seriesID: seriesID,
            sopID: sopID,
          }
        )
      ) {
        navigate(
          getImageUrl({
            studyID: studyID,
            seriesID: seriesID,
            sopID: sopID,
            datasetID: datasetID,
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  // URL for navigation
  useEffect(() => {
    navigateToImageIfChanged({
      datasetID: currentDatasetID,
      studyID: currentStudyID!,
      seriesID: currentSeriesID,
      sopID: currentSopID,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [reportPHIModalOpen, reportPHIModalOpenChange] = useState(false);
  const [reportCorruptedImageModalOpen, reportCorruptedImageModalOpenChange] =
    useState(false);

  // Fetch presigned urls for the current series
  const {data: presignedSeries, isError: presignedSeriesIsError} = useQuery(
    ['studyDicom', currentStudyID, currentSeriesID],
    () => {
      return fetchSeriesDicomPresignedUrls(
        api,
        currentStudyID!,
        currentSeriesID!
      );
    },
    {
      enabled: !_.isNil(currentStudyID) && !_.isNil(currentSeriesID),
      keepPreviousData: false,
      staleTime: 12 * 60 * 60 * 1000, // 12 hours,
    }
  );

  useEffect(() => {
    if (currentSeriesID === undefined && series?.length) {
      navigateToImageIfChanged({
        datasetID: currentDatasetID,
        studyID: currentStudyID!,
        seriesID: series[0].seriesID,
      });
    } else if (
      presignedSeries?.images.length &&
      currentSopID === undefined &&
      !_.some(presignedSeries.images, image => image.sopID === currentSopID)
    ) {
      navigateToImageIfChanged({
        datasetID: currentDatasetID,
        studyID: currentStudyID!,
        seriesID: currentSeriesID,
        sopID: presignedSeries.images[0].sopID,
      });
    }
  }, [
    series,
    navigate,
    getImageUrl,
    currentDatasetID,
    currentStudyID,
    presignedSeries,
    navigateToImageIfChanged,
    currentSeriesID,
    currentSopID,
  ]);

  const {data: evalonResults, isFetched: evalonResultsIsFetched} = useQuery<
    FullEvalonResultsResp[] | undefined
  >(
    ['evalonResultsForAnStudyID', currentStudyID],
    () =>
      fetchEvalonDicomocrByStudyID(api, currentStudyID!).catch(err => {
        const message =
          err?.response?.data?.message ??
          'Error fetching evalon studyID results';
        quickToast({title: message, icon: 'error'});
        return undefined;
      }),
    {
      enabled: !_.isNil(currentStudyID) && !!isAdmin,
      keepPreviousData: false,
      staleTime: 10 * 60 * 1000, // 10 minutes
    }
  );

  const {data: report, error: reportError} = useQuery(
    ['report', currentStudyID],
    () => {
      return fetchReport(api, currentStudyID as string);
    },
    {
      enabled: _.isString(currentStudyID),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const navStudies: NavStudy[] = useMemo(() => {
    return (
      _.orderBy(studyDicomInfo?.studies, ['studyID'], ['asc']).map(study => {
        return {
          studyID: study.studyID,
        };
      }) ?? []
    );
  }, [studyDicomInfo]);

  const getDicomDimensions = (dicomImageData: ArrayBuffer) => {
    const byteArray = new Uint8Array(dicomImageData);
    const dataSet = dicomParser.parseDicom(byteArray);

    const width = dataSet.uint16('x00280011'); // Columns
    const height = dataSet.uint16('x00280010'); // Rows

    return {
      width: width as number,
      height: height as number,
    };
  };

  const formatImagePresignedUrls = (
    images: DicomImagePresigned[],
    study_id: string,
    series_id: string,
    series_num?: string
  ) => {
    const formattedImageUrls = images.flatMap(image => {
      const isDicom = image.presignedUrl.split('?')[0].endsWith('.dcm');

      if (isDicom && image.numFrames > 1) {
        return getAllFramesImageIds(
          image.presignedUrl,
          image.numFrames,
          study_id,
          series_id,
          image.sopID,
          series_num,
          image.instanceNum
        );
      } else {
        const frameImageId = isDicom
          ? `wadouri:${image.presignedUrl}`
          : `web:${image.presignedUrl}`;
        const imageData: ImageDetail = {
          presignedUrl: frameImageId,
          seriesID: series_id,
          sopID: image.sopID,
          seriesNum: series_num,
          instanceNum: image.instanceNum,
          frame: 0,
        };
        return [imageData];
      }
    });
    return formattedImageUrls;
  };

  const imagesDataQueries = useQueries(
    (presignedSeries !== undefined
      ? formatImagePresignedUrls(
          presignedSeries.images,
          presignedSeries.studyID,
          presignedSeries.seriesID,
          presignedSeries.seriesNum
        )
      : []
    )
      .filter(imageDetail => imageDetail?.presignedUrl !== undefined)
      .map(imageDetail => ({
        queryKey: ['imageData', imageDetail.sopID],
        enabled: presignedSeries !== undefined,
        keepPreviousData: false,
        queryFn: async () => {
          const isDicom = imageDetail.presignedUrl
            .split('?')[0]
            .endsWith('.dcm');
          if (isDicom) {
            const url = imageDetail.presignedUrl.replace('wadouri:', '');

            // Processing for DICOM images
            const dicomDiemnsions = await fetch(url)
              .then(response => response.arrayBuffer())
              .then(getDicomDimensions)
              .catch(error => {
                console.error(`Error loading DICOM image: ${error}`);
              });

            return {
              sopID: imageDetail.sopID,
              dimensions: dicomDiemnsions,
            };
          } else {
            const url = imageDetail.presignedUrl.replace('web:', '');
            const metadata = await getImageMetadata(url);

            return {
              sopID: imageDetail.sopID,
              dimensions: {
                width: metadata.width,
                height: metadata.height,
              } as Dimensions,
            };
          }
        },
        staleTime: Infinity,
      }))
  );

  const allImagesLoaded =
    presignedSeries !== undefined &&
    imagesDataQueries.every(imageData => imageData.isSuccess);

  const presignedUrls: ImageDetail[] | undefined = useMemo(
    () =>
      presignedSeries !== undefined && allImagesLoaded
        ? formatImagePresignedUrls(
            presignedSeries.images,
            presignedSeries.studyID,
            presignedSeries.seriesID,
            presignedSeries.seriesNum
          ).map(presignedUrl => {
            const imageData = _.find(
              imagesDataQueries.map(imageData => imageData.data),
              {sopID: presignedUrl.sopID}
            );
            const imageExists = imageData !== undefined;
            if (!imageExists) {
              console.error(
                'could not find dimensions for image',
                presignedUrl.sopID
              );
            }
            return {
              ...presignedUrl,
              width: imageData?.dimensions?.width,
              height: imageData?.dimensions?.height,
            } as ImageDetail;
          })
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allImagesLoaded, imagesDataQueries, presignedSeries, currentStudyID]
  );

  const prevButtonPressed = () => {
    if (!_.isNil(studyNavigation?.prevStudy)) {
      navigateToImageIfChanged({
        datasetID: currentDatasetID,
        studyID: studyNavigation!.prevStudy.studyID,
      });
    }
  };

  const nextButtonPressed = () => {
    if (!_.isNil(studyNavigation?.nextStudy)) {
      navigateToImageIfChanged({
        datasetID: currentDatasetID,
        studyID: studyNavigation!.nextStudy.studyID,
      });
    }
  };

  useEffect(() => {
    // Setup navigation
    if (
      (!_.isEmpty(navStudies) &&
        !_.isNil(currentStudyID) &&
        studyNavigation?.currentStudy?.studyID !== currentStudyID) ||
      !_.isNil(currentSeriesID) ||
      studyNavigation?.currentStudy?.seriesID !== currentSeriesID
    ) {
      setupStudyNavigation(currentStudyID!, navStudies, currentSeriesID);
    }
  }, [
    navStudies,
    currentDatasetID,
    navigate,
    reportError,
    studyNavigation?.currentStudy,
    currentStudyID,
    isAdmin,
    currentSeriesID,
  ]);

  const reportPhiMut = useMutation(
    ({studyID, seriesID}: {studyID: string; seriesID: string}) =>
      updateDICOMPHI(api, [
        {
          study_id: studyID,
          series_id: seriesID,
        },
      ]),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['studyDicom', currentStudyID]);
        reportPHIModalOpenChange(false);
        // Change current series to another one if available
        if (series?.length && series?.length > 1) {
          const nextSeries =
            series[0].seriesID === currentSeriesID
              ? series[1].seriesID
              : series[0].seriesID;

          navigateToImageIfChanged({
            datasetID: currentDatasetID,
            studyID: currentStudyID!,
            seriesID: nextSeries,
          });
        } else {
          if (!isAdmin) {
            navigate(`/datasets/${currentDatasetID}/report/${currentStudyID}`);
          }
        }
      },
    }
  );

  const navigateToImage = useCallback(
    ({
      datasetID,
      studyID,
      seriesID,
      sopID,
    }: {
      datasetID?: number;
      studyID: string;
      seriesID?: string;
      sopID?: string;
    }) =>
      navigate(
        getImageUrl({
          datasetID: datasetID,
          studyID: studyID,
          seriesID: seriesID,
          sopID: sopID,
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getImageUrl]
  );

  const reportPHIModal = () => {
    return (
      <ReportPHIModal
        isOpen={reportPHIModalOpen}
        onClose={() => reportPHIModalOpenChange(false)}
        onSubmit={() => {
          if (currentStudyID && currentSeriesID) {
            reportPhiMut.mutate({
              studyID: currentStudyID,
              seriesID: currentSeriesID,
            });
          }
        }}
        isLoading={reportPhiMut.isLoading}
        currentSeriesID={currentSeriesID}
        series={series}
        currentStudyID={currentStudyID}
      />
    );
  };

  const reportCorruptedImageModal = () => {
    return (
      <ReportCorruptedImageModal
        isOpen={reportCorruptedImageModalOpen}
        onClose={() => reportCorruptedImageModalOpenChange(false)}
        onSubmit={feedback => {
          reportCorruptedDicom(api, feedback, window.location.href);
          reportCorruptedImageModalOpenChange(false);
        }}
      />
    );
  };

  const getSeriesOptions = (series: DicomSeries[]) =>
    series.map(seriesOption => {
      return {
        value: seriesOption.seriesID,
        label: `${
          !_.isEmpty(seriesOption.seriesDesc)
            ? seriesOption.seriesDesc
            : `Series: ${seriesOption.seriesID.toString()}`
        } (${seriesOption.imageCount} DICOM images)`,
      };
    });

  const getSeriesDesc = (seriesID: string, series?: DicomSeries[]) => {
    const matched = series?.find(ser => ser.seriesID === seriesID);

    return matched?.seriesDesc || `Series: ${seriesID}`;
  };

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Dicom Viewer</title>
      </Helmet>

      <ApplicationShell contained={false}>
        <div className="mb-3">
          {!_.isNil(currentDatasetID) && (
            <Breadcrumbs
              links={
                !isAdmin
                  ? [
                      {name: 'Your Datasets', href: '/datasets'},
                      {
                        name:
                          datasetWithStudies?.dataset.name ??
                          `Dataset ID: ${currentDatasetID}`,
                        href: `/datasets/${currentDatasetID}`,
                      },
                      {
                        name: `Study ${
                          truncateStudyID(report?.studyId ?? '') || 'Report'
                        }`,
                        href: `/datasets/${currentDatasetID}/report/${currentStudyID}`,
                      },
                      {
                        name: 'DicomViewer',
                        current: true,
                      },
                    ]
                  : [
                      {name: 'Datasets Review', href: '/admin/datasets/review'},
                      {
                        name: `Dataset ${currentDatasetID} review`,
                        href: `/admin/datasets/review/${currentDatasetID}`,
                      },
                      {
                        name: `Study ${
                          truncateStudyID(report?.studyId ?? '') || 'Report'
                        }`,
                        href: report?.studyId
                          ? `/admin/datasets/review/${currentDatasetID}/report/${report?.studyId}/dicomocr`
                          : undefined,
                      },
                      {
                        name: 'DicomViewer',
                        current: true,
                      },
                    ]
              }
            />
          )}
        </div>
        {presignedSeriesIsError ||
        studyDicomInfoIsError ||
        (presignedSeries !== undefined &&
          presignedSeries.images.length === 0) ? (
          <div>Error loading web dicom viewer</div>
        ) : presignedSeries?.images.length && series ? (
          <>
            <div className="flex justify-between items-center text-2xl font-bold text-gray-900 mb-1">
              <div className="flex flex-col text-xs font-normal text-gray-400">
                <div className="text-2xl font-bold text-gray-900">
                  DICOM Viewer
                </div>
                <div>Patient ID: {report?.patientId}</div>
                <div>Study ID: {report?.studyId}</div>
              </div>

              <div className="flex flex-col">
                <div className="flex items-center">
                  <div className="mx-2">Series:</div>
                  <Select
                    text={getSeriesDesc(presignedSeries.seriesID, series)}
                    options={getSeriesOptions(series)}
                    onSelect={(option: SelectOption<string>) => {
                      const seriesOption = series?.find(
                        seriesElement => seriesElement.seriesID === option.value
                      );
                      if (seriesOption) {
                        navigateToImageIfChanged({
                          datasetID: currentDatasetID,
                          studyID: currentStudyID!,
                          seriesID: seriesOption.seriesID,
                        });
                      }
                    }}
                  />
                  <button
                    className="btn btn-white ml-6"
                    onClick={() => reportPHIModalOpenChange(true)}
                  >
                    <HiOutlineShieldExclamation className="h-5 w-5 inline mr-1 align-middle" />
                    Report PHI
                  </button>
                  <button
                    className="btn btn-white ml-6"
                    onClick={() => reportCorruptedImageModalOpenChange(true)}
                  >
                    <HiOutlineExclamationCircle className="h-5 w-5 inline mr-1 align-middle" />
                    Report Corrupted Image
                  </button>
                </div>

                <div className="mt-2 mx-2 text-xs font-normal text-gray-400">{`(Currently Viewing ${formatOrdinalNumber(
                  (studyNavigation?.currentStudyIndex ?? 0) + 1
                )} of ${studyNavigation?.length ?? 1} Studies)`}</div>
              </div>
            </div>
            {currentStudyID &&
              currentSeriesID &&
              currentSopID &&
              presignedUrls !== undefined &&
              (!isAdmin || evalonResultsIsFetched) &&
              (!blackoutsEnabled || savedBlackoutRectangles !== undefined) && (
                <Suspense fallback={<Loading />}>
                  <DicomViewer
                    key={currentSeriesID}
                    imageIds={presignedUrls}
                    showPrevStudyButton={!_.isNil(studyNavigation?.prevStudy)}
                    showNextStudyButton={!_.isNil(studyNavigation?.nextStudy)}
                    onPrevStudyButtonClick={() => prevButtonPressed()}
                    onNextStudyButtonClick={() => nextButtonPressed()}
                    studyID={currentStudyID}
                    seriesID={currentSeriesID}
                    sopID={currentSopID}
                    isAdmin={isAdmin}
                    savedBlackoutRectanglesIsFetching={
                      savedBlackoutRectanglesIsFetching
                    }
                    savedBlackoutRectangles={savedBlackoutRectangles}
                    activeBlackouts={activeBlackouts}
                    activeBlackoutsChange={b => {
                      activeBlackoutsChange(b);
                    }}
                    currentSeriesIDChange={seriesID => {
                      navigateToImageIfChanged({
                        datasetID: currentDatasetID,
                        studyID: currentStudyID!,
                        seriesID: seriesID,
                      });
                    }}
                    currentSopIDChange={sopID => {
                      if (currentSopID !== sopID) {
                        navigateToImage({
                          datasetID: currentDatasetID,
                          studyID: currentStudyID!,
                          seriesID: currentSeriesID,
                          sopID: sopID,
                        });
                      }
                    }}
                    series={series}
                    evalonResults={evalonResults}
                  />
                </Suspense>
              )}
          </>
        ) : (
          <Loading />
        )}
      </ApplicationShell>
      {reportPHIModalOpen && reportPHIModal()}
      {reportCorruptedImageModalOpen && reportCorruptedImageModal()}
    </>
  );
};
