import React, {useState} from 'react';
import {HiOutlineExclamationCircle} from 'react-icons/hi';
import {Modal} from '../../core/layout/modal';

interface ReportCorruptedImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (feedback: string) => void;
}

export const ReportCorruptedImageModal: React.FC<
  ReportCorruptedImageModalProps
> = ({isOpen, onClose, onSubmit}) => {
  const [feedback, setFeedback] = useState('');

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="max-w-lg">
      <div className="mb-6">
        <div className="flex items-center mb-3">
          <HiOutlineExclamationCircle className="mr-1 w-5 h-auto text-red-600" />
          <div className="text-lg">Report Corrupted Image</div>
        </div>
        <div className="mt-4">
          <label htmlFor="additionalDetails" className="text-sm text-gray-600">
            Please provide details about what is wrong with the image
          </label>
          <textarea
            id="additionalDetails"
            onChange={e => setFeedback(e.target.value)}
            className="w-full h-20 p-2 mt-2 border rounded-md"
            placeholder="Describe the corruption..."
          />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between">
        <button className="btn btn-white" onClick={onClose}>
          Cancel
        </button>
        <button
          disabled={!feedback}
          className="btn btn-danger"
          onClick={() => onSubmit(feedback)}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};
