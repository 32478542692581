import {ReactNode} from 'react';
import {Switch} from '@headlessui/react';

import {Toggle} from './toggle';

export const ToggleWithLeftLabelDescription = ({
  checked,
  onChange,
  label,
  description,
  disabled = false,
}: {
  checked: boolean;
  onChange(checked: boolean): void;
  label?: string;
  description?: ReactNode;
  disabled?: boolean;
}) => {
  return (
    <Switch.Group
      as="div"
      className="flex items-center justify-between space-x-2"
    >
      <span className="flex-grow flex flex-col">
        {label && (
          <Switch.Label
            as="span"
            className="text-sm font-medium text-gray-900"
            passive
          >
            {label}
          </Switch.Label>
        )}
        {description && (
          <Switch.Description as="span" className="text-sm text-gray-500">
            {description}
          </Switch.Description>
        )}
      </span>
      <Toggle checked={checked} onChange={onChange} disabled={disabled} />
    </Switch.Group>
  );
};
