import _ from 'lodash';

export const calculateAgeInMonths = (patientAge: string) => {
  let ageInMonths = 0;

  if (!_.isNil(patientAge)) {
    const yearsMatch = patientAge.match(/(\d+)Y/);

    if (yearsMatch) {
      ageInMonths += parseInt(yearsMatch[1]) * 12;
    }
  }

  return ageInMonths;
};
