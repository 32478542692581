import {useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import _ from 'lodash';

import {Modal} from '../../core/layout/modal';
import {MenuDropdownSingle} from '../../core/components/menu';
import {
  Dataset,
  datasetStatusToStage,
  DATASET_STAGE_TYPE,
  fetchAllDatasets,
  updateDatasetReports,
} from '../../models/dataset';
import {
  ReportSelectionState,
  reportSelectionToDatasetUpdateRequest,
} from '../../hooks/report-selection-provider';
import {CreateDatasetForm} from '../create-dataset-form';
import {updateCachedTags} from '../../models/tags';
import {useAxios} from 'src/utils/http';

export const AddToDatasetDropdown = ({
  datasetFilter = datasets => datasets,
  disabled = false,
  align = 'left',
  added,
  reportSelectionState,
}: {
  disabled?: boolean;
  align?: 'left' | 'right';
  datasetFilter?: (datasets: Dataset[]) => Dataset[];
  added?: (dataset: Dataset) => void;
  reportSelectionState: ReportSelectionState;
}) => {
  const [modalOpen, modalOpenChange] = useState(false);
  const queryClient = useQueryClient();
  const http = useAxios();

  const openModal = () => {
    modalOpenChange(true);
  };

  const closeModal = () => {
    modalOpenChange(false);
  };

  const {data: allDatasets} = useQuery(
    ['dataset'],
    () => fetchAllDatasets(http),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const datasets = allDatasets
    ? datasetFilter(allDatasets).filter(
        dataset =>
          datasetStatusToStage(dataset.status) ===
          DATASET_STAGE_TYPE.DatasetCreated
      )
    : undefined;

  const updateDatasetStudiesMut = useMutation(
    ({
      datasetId,
      studySelectionState,
      operation,
    }: {
      datasetId: number;
      studySelectionState: ReportSelectionState;
      operation: 'add' | 'remove';
    }) =>
      updateDatasetReports(
        http,
        datasetId,
        reportSelectionToDatasetUpdateRequest(studySelectionState),
        operation
      ),
    {
      onSuccess: (result, {studySelectionState}) => {
        queryClient.invalidateQueries(['dataset'], {exact: true});
        queryClient.invalidateQueries(['dataset', result.dataset.id]);
        updateCachedTags(http, queryClient, [
          ...Array.from(studySelectionState.add.ids),
          ...Array.from(studySelectionState.remove.ids),
        ]);

        added && added(result.dataset);
      },
    }
  );

  // @todo: Use a component for this
  const createDatasetModal = () => {
    return (
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => closeModal()}
        className="max-w-lg"
      >
        <CreateDatasetForm
          selectedStudyIDs={Array.from(reportSelectionState.add.ids)}
          closeModal={closeModal}
        />
      </Modal>
    );
  };

  return (
    <>
      <MenuDropdownSingle
        align={align}
        buttonText={
          <span data-cy="AddToDatasetDropdown">
            {!updateDatasetStudiesMut.isLoading
              ? 'Add to Dataset'
              : 'Adding to Dataset...'}
          </span>
        }
        buttonClassName="btn-xs"
        menuButtonDisabled={disabled || updateDatasetStudiesMut.isLoading}
        alwaysShowDivider={true} // Enable divider between all items except the last
        menuItems={[
          ...(!_.isEmpty(datasets)
            ? datasets!.map(dataset => ({
                itemText: () => dataset.name,
                onClick: () => {
                  updateDatasetStudiesMut.mutate({
                    datasetId: dataset.id,
                    studySelectionState: reportSelectionState,
                    operation: 'add',
                  });
                },
              }))
            : [
                {
                  itemText: () => 'No available datasets',
                  disabled: true,
                },
              ]),
          {
            itemText: () => (
              <span data-cy="AddToDatasetDropdown__createNewDatasetButton">
                Create new dataset
              </span>
            ),
            disabled: disabled,
            onClick: () => openModal(),
          },
        ]}
      />
      {createDatasetModal()}
    </>
  );
};
