import React from 'react';
import {HiOutlineShieldExclamation} from 'react-icons/hi';
import {Modal} from '../../core/layout/modal';
import {DicomSeries} from '../../models/dicom-viewer';

interface ReportPHIModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  currentSeriesID?: string;
  series?: DicomSeries[];
  currentStudyID?: string;
}

const getSeriesDesc = (seriesID: string, series?: DicomSeries[]) => {
  const matched = series?.find(ser => ser.seriesID === seriesID);
  return matched?.seriesDesc || `Series: ${seriesID}`;
};

export const ReportPHIModal: React.FC<ReportPHIModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  currentSeriesID,
  series,
  currentStudyID,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="max-w-lg">
      <div className="mb-6">
        <div className="flex items-center mb-3">
          <HiOutlineShieldExclamation className="mr-1 w-5 h-auto text-red-600" />
          <div className="text-lg">Report PHI</div>
        </div>
        <div className="text-sm text-gray-500">
          Report PHI in DICOMs in{' '}
          <span className="font-semibold">
            {currentSeriesID && getSeriesDesc(currentSeriesID, series)}
          </span>
          . All DICOMs in the selected series will be marked as PHI. This action
          can not be undone.
        </div>
      </div>
      <div className="flex flex-row items-center justify-between">
        <button className="btn btn-white" onClick={onClose}>
          Cancel
        </button>
        <button
          disabled={!currentStudyID || !currentSeriesID || isLoading}
          className="btn btn-danger"
          onClick={onSubmit}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};
