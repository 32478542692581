import {Helmet} from 'react-helmet';
import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {ApplicationShell} from '../../core/layout/application-shell';
import {Modal} from '../../core/layout/modal';
import {useAuth} from '../../hooks/auth';
import {
  disableExternalUserLogins,
  enableExternalUserLogins,
} from '../../models/kill-switch';
import {useAxios} from 'src/utils/http';
import {ToggleWithLeftLabelDescription} from 'src/core/components/toggle';
import {Card} from 'src/core/layout/card';

export const doNotAutoFill = 'doNotAutoFill';

export const ToolsPage = () => {
  const {authState} = useAuth();
  const api = useAxios();
  const isAdmin = authState.profile!.admin;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState<() => void>(() => {});
  const [modalMessage, setModalMessage] = useState('');
  const [autofillEnabled, autofillEnabledChange] = useState(
    localStorage.getItem(doNotAutoFill) !== doNotAutoFill
  );

  const showModal = (actionFunction: () => Promise<void>, message: string) => {
    setModalAction(() => actionFunction);
    setModalMessage(message);
    setIsModalOpen(true);
  };

  interface ConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onProceed: () => void;
    message: string;
  }

  const handleEnableExternalLogins = async () => {
    try {
      await enableExternalUserLogins(api);
      toast.success('External user logins have been enabled.');
    } catch (error) {
      console.error('Failed to enable external user logins:', error);
      toast.error('Error enabling external user logins.');
    }
  };

  const handleDisableExternalLogins = async () => {
    try {
      await disableExternalUserLogins(api);
      toast.success('External user logins have been disabled.');
    } catch (error) {
      console.error('Failed to disable external user logins:', error);
      toast.error('Error disabling external user logins.');
    }
  };

  const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    isOpen,
    onClose,
    onProceed,
    message,
  }) => {
    return (
      <Modal isOpen={isOpen} onRequestClose={onClose} className="max-w-lg">
        <div className="p-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {message}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to proceed with this action?
            </p>
          </div>
          <div className="mt-4 flex justify-end">
            <button
              onClick={onClose}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 mr-2"
            >
              Cancel
            </button>
            <button
              onClick={onProceed}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
            >
              Proceed
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <Helmet>
        <title>Segmed Openda - Tools</title>
      </Helmet>

      <ApplicationShell contained={false}>
        <div className="text-2xl font-medium mb-4">Segmed Tools</div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-1 space-x-2 mt-4">
          <Card>
            <ToggleWithLeftLabelDescription
              checked={autofillEnabled}
              onChange={checked => {
                if (checked) {
                  localStorage.removeItem(doNotAutoFill);
                } else {
                  localStorage.setItem(doNotAutoFill, doNotAutoFill);
                }
                autofillEnabledChange(checked);
              }}
              label="Search Synonyms"
            />
          </Card>
        </div>

        {isAdmin && (
          <>
            <br />
            <div className="text-xl font-medium mb-4">
              Admin Only - Kill Switch - Danger Zone - User Login Control
            </div>

            <div className="grid grid-cols-1 space-y-2">
              <button
                onClick={() =>
                  showModal(
                    handleDisableExternalLogins,
                    'Disable external user logins'
                  )
                }
                className="btn btn-danger"
              >
                Disable external user logins
              </button>
              <button
                onClick={() =>
                  showModal(
                    handleEnableExternalLogins,
                    'Enable external user logins'
                  )
                }
                className="bg-green-500 text-white font-bold py-2 px-4 rounded"
              >
                Enable external user logins
              </button>
            </div>
          </>
        )}
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onProceed={() => {
            modalAction();
            setIsModalOpen(false);
          }}
          message={modalMessage}
        />
      </ApplicationShell>
    </>
  );
};
