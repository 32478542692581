/* eslint-disable security/detect-object-injection */
import React, {ReactNode} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import cx from 'classnames';
import {
  HiExclamationCircle,
  HiOutlineCheckCircle,
  HiOutlineX,
  HiOutlineXCircle,
} from 'react-icons/hi';

const contextClass: {[key: string]: string} = {
  success: '',
  error: '',
  info: '',
  warning: '',
  default: '',
  dark: '',
};

export const ToastWrapper = ({children}: {children: ReactNode}) => {
  return <div className="flex items-start">{children}</div>;
};

export const ToastMessage = ({
  title,
  icon,
  children,
}: {
  title: string;
  icon?: 'success' | 'error' | 'warning';
  children?: ReactNode;
}) => {
  const renderIcon = (icon: string) => {
    if (icon === 'success') {
      return (
        <div className="flex-shrink-0">
          <HiOutlineCheckCircle className="h-6 w-6 text-emerald-400" />
        </div>
      );
    } else if (icon === 'error') {
      return (
        <div className="flex-shrink-0">
          <HiOutlineXCircle className="h-6 w-6 text-red-400" />
        </div>
      );
    } else if (icon === 'warning') {
      return (
        <div className="flex-shrink-0">
          <HiExclamationCircle className="h-6 w-6 text-yellow-400" />
        </div>
      );
    }

    return <></>;
  };

  return (
    <ToastWrapper>
      {icon && renderIcon(icon)}

      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium">{title}</p>
        {children && (
          <div className="mt-1 text-sm text-gray-500">{children}</div>
        )}
      </div>
    </ToastWrapper>
  );
};

export const CustomToastContainer = () => {
  const closeButton = ({closeToast}: {closeToast: () => void}) => (
    <button
      className="flex-shrink-1 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      onClick={() => closeToast()}
      data-cy="closeToastButton"
    >
      <span className="sr-only">Close</span>
      <HiOutlineX className="h-5 w-5" />
    </button>
  );

  return (
    <ToastContainer
      toastClassName={props => {
        const type = props?.type ?? 'default';
        return cx(
          contextClass[type],
          'bg-white shadow-lg rounded-lg ring-1 ring-black ring-opacity-5 flex items-start p-1 min-h-10 rounded-md justify-between overflow-hidden p-4 mb-2'
        );
      }}
      bodyClassName={() => 'text-sm text-gray-900 font-med flex-1'}
      position="top-right"
      autoClose={5000}
      hideProgressBar
      closeButton={closeButton}
      draggable={false}
      closeOnClick={false}
      containerId="toastNotifications"
    />
  );
};

export const quickToast = ({
  title,
  icon,
  children,
}: {
  title: string;
  icon?: 'success' | 'error';
  children?: ReactNode;
}) => {
  return toast(
    <ToastMessage title={title} icon={icon}>
      {children}
    </ToastMessage>
  );
};
