import {useMemo} from 'react';
import {Column, Row} from 'react-table';
import {Duration} from 'luxon';
import _ from 'lodash';
import {
  TextFilter,
  MinMaxFilter,
  CheckboxFilter,
} from '../../../core/components/react-table';
import {trackEvent} from 'src/utils/tracking';
import {DatasetStudy} from '../../../models/dataset';
import {DicomInfo, getDicomSeries} from '../../../models/dicom-viewer';
import {truncateStudyID} from '../../../models/report';
import {Badge} from '../../../core/components/badge';
import {Tag} from '../../../components/tags';
import {Link} from 'react-router-dom';
import {MenuDropdownSingle} from '../../../core/components/menu';
import {HiOutlineChevronDown, HiOutlineChevronUp} from 'react-icons/hi';
import {DicomHeader} from 'src/models/dicom-header';
import {calculateAgeInMonths} from 'src/utils/calculateAgeInMonths';

export const useMetadataTableColumns = (
  datasetId: number,
  dicomHeaders: DicomHeader[] | undefined,
  visibleDicomHeaders: string[],
  datasetDicomInfo: DicomInfo | undefined,
  internalUser: boolean
) => {
  console.log(visibleDicomHeaders, 'dicom visible');
  console.log(datasetDicomInfo, 'dicom Info');
  return useMemo<Array<Column<DatasetStudy>>>(
    () => [
      {
        id: 'table',
        sticky: 'left',
        Header: '',
        disableResizing: true,
        getResizerProps: () => {},
        columns: [
          {
            id: 'expand',
            width: 65,
            Header: '',
            accessor: _.constant(null),
            disableSortBy: true,
            disableFilters: true,
            disableResizing: true,
            getResizerProps: () => {},
            Cell: ({row}: {row: Row<DatasetStudy>}) => {
              return (
                <>
                  {!_.isEmpty(row.original.metadata?.series) && (
                    <span
                      className="inline-block align-middle"
                      {...row.getToggleRowExpandedProps()}
                    >
                      {row.isExpanded ? (
                        <HiOutlineChevronUp />
                      ) : (
                        <HiOutlineChevronDown />
                      )}
                    </span>
                  )}
                </>
              );
            },
          },
          {
            id: 'index',
            width: 100,
            Header: '#',
            disableResizing: true,
            getResizerProps: () => {},
            Cell: () => {
              return <></>;
            },
            disableSortBy: true,
            disableFilters: true,
          },
          {
            id: 'selected',
            width: 85,
            disableResizing: true,
            getResizerProps: () => {},
            Header: ({
              getToggleAllPageRowsSelectedProps,
              toggleAllRowsSelected,
              filteredRows,
              page,
            }) => {
              const {indeterminate: pageIndeterminate, ...pageRestProps} =
                getToggleAllPageRowsSelectedProps();

              return (
                <div className="flex items-center">
                  <input
                    id="toggleAllPageRowsSelected"
                    type="checkbox"
                    className="checkbox-input"
                    data-cy="selectAllStudies"
                    ref={el =>
                      el && (el.indeterminate = pageIndeterminate ?? false)
                    }
                    {...pageRestProps}
                  />
                  <div>
                    <MenuDropdownSingle
                      buttonText=""
                      useDefaultButtonClass={false}
                      buttonClassName="px-2"
                      align="right"
                      menuButtonDisabled={filteredRows.length === 0}
                      menuItems={[
                        {
                          itemText: () => (
                            <div>Select all {page.length} on page</div>
                          ),
                          onClick: () =>
                            document
                              .getElementById('toggleAllPageRowsSelected')
                              ?.click(),
                        },
                        {
                          itemText: () => (
                            <div>
                              Select all {filteredRows.length} in dataset
                            </div>
                          ),
                          onClick: () => toggleAllRowsSelected(),
                        },
                      ]}
                    />
                  </div>
                </div>
              );
            },
            Cell: ({row}: {value: boolean; row: Row<DatasetStudy>}) => {
              const {indeterminate, ...rest} = row.getToggleRowSelectedProps();

              return (
                <>
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    data-cy="selectStudy"
                    ref={el =>
                      el && (el.indeterminate = indeterminate ?? false)
                    }
                    {...rest}
                  />
                </>
              );
            },
            disableSortBy: true,
            disableFilters: true,
          },
          {
            id: 'approval',
            Header: 'Order Cart',
            disableResizing: true,
            getResizerProps: () => {},
            sortType: (rowA, rowB) => {
              // this function runs every time a study is added/excluded/set to undetermined and sorts the rows based on the value in their approved field
              return rowA.original.approved > rowB.original.approved ? -1 : 1;
            },
            accessor: row => {
              return row.approved === 1
                ? 'Added'
                : row.approved === -1
                ? 'Excluded'
                : 'Undetermined';
            },
            width: 170,
            Cell: ({value}: {value: string | null}) => {
              if (value === null) {
                return null;
              }

              return (
                <Badge
                  type={
                    value === 'Added'
                      ? 'success'
                      : value === 'Excluded'
                      ? 'danger'
                      : 'info'
                  }
                >
                  {value}
                </Badge>
              );
            },
          },
          {
            Header: 'Study ID',
            id: 'studyId',
            width: 120,
            accessor: study => study.studyId.slice(-8),
            Cell: ({value, row}: {value?: string; row: Row<DatasetStudy>}) =>
              !_.isNil(value) ? (
                <a
                  href={`/datasets/${datasetId}/report/${row.original.studyId}`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={e => e.preventDefault()}
                  className="text-primary hover:text-primary-active"
                >
                  {truncateStudyID(value)}
                </a>
              ) : null,
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            Header: 'View Dicoms',
            id: 'viewDicom',
            width: 130,
            accessor: study =>
              !getDicomSeries(study.studyId, datasetDicomInfo)
                ? 'DICOMs are not available to view for this Study.'
                : 'View DICOMs',
            Cell: ({value, row}: {value?: string; row: Row<DatasetStudy>}) => {
              const dicomsCanBeViewed =
                getDicomSeries(row.original.studyId, datasetDicomInfo) &&
                !internalUser;
              return !_.isNil(value) ? (
                <Link
                  className="btn btn-xs btn-primary -my-1 w-24"
                  to={
                    dicomsCanBeViewed
                      ? `${location.pathname}/report/${row.original.studyId}/dicomViewer`
                      : '#'
                  }
                  target="_blank"
                >
                  <button
                    onClick={() => {
                      trackEvent('viewdicom_datasets', {
                        studyId: row.original.studyId,
                        user_type: internalUser ? 'internal' : 'external',
                      });
                    }}
                    className="btn btn-xs btn-primary -my-2"
                    disabled={!dicomsCanBeViewed}
                  >
                    View DICOM
                  </button>
                </Link>
              ) : null;
            },
            Filter: TextFilter,
            filter: 'inclues',
          },
        ],
      },
      {
        Header: 'Patient',
        columns: [
          {
            Header: 'Patient ID',
            accessor: 'patientId',
            Cell: ({value}: {value?: string}) => value?.slice(-10) ?? null,
            Filter: TextFilter,
            filter: 'inclues',
            width: 250,
          },
          {
            Header: 'Age',
            id: 'patientAge',
            accessor: row => {
              const patientAge = row.patientAge;
              if (typeof patientAge === 'string') {
                const yearsMatch = patientAge.match(/(\d+)Y/);
                if (yearsMatch) {
                  return Duration.fromObject({
                    years: parseInt(yearsMatch[1]),
                  }).as('days');
                }
              }

              return 0;
            },
            Cell: ({row}: {value: Duration; row: Row<DatasetStudy>}) => {
              let res = row.original.patientAge;
              if (res[res.length - 1]?.toUpperCase() === 'Y') {
                res = res.slice(0, res.length - 1);
              }
              return res;
            },
            Filter: MinMaxFilter,
            filter: (rows, _columnIds, filterValue = []) => {
              if (!_.isNil(filterValue[0])) {
                rows = _.filter(rows, row => {
                  const patientAge = row.original.patientAge;
                  const ageInMonths = calculateAgeInMonths(patientAge);
                  return (
                    ageInMonths >=
                    Duration.fromObject({years: filterValue[0]}).as('months')
                  );
                });
              }
              if (!_.isNil(filterValue[1])) {
                rows = _.filter(rows, row => {
                  const patientAge = row.original.patientAge;
                  const ageInMonths = calculateAgeInMonths(patientAge);
                  return (
                    ageInMonths <=
                    Duration.fromObject({years: filterValue[1]}).as('months')
                  );
                });
              }
              return rows;
            },
          },
          {
            Header: 'Sex',
            accessor: 'patientSex',
            Filter: TextFilter,
            filter: 'inclues',
          },
        ],
      },
      {
        Header: 'Study',
        columns: [
          {
            Header: 'Modality',
            id: 'modality',
            accessor: 'modality',
            Filter: CheckboxFilter,
            filter: 'includesSome',
          },
          {
            Header: 'Body Part',
            id: 'bodyPart',
            accessor: 'bodyPart',
            Filter: CheckboxFilter,
            filter: 'includesSome',
          },
          {
            Header: 'Exam Date',
            id: 'examDate',
            accessor: 'examDate',
            Cell: ({value}) => value?.toFormat('LLL dd, yyyy') ?? '',
            Filter: TextFilter,
            filter: 'inclues',
            sortType: (rowA, rowB) => {
              const a = rowA.original.examDate?.toSeconds() ?? 0;
              const b = rowB.original.examDate?.toSeconds() ?? 0;
              const diff = a - b;

              if (diff > 0) {
                return 1;
              }
              if (diff < 0) {
                return -1;
              }

              return 0;
            },
          },
          {
            Header: 'Source Location',
            id: 'sourceLocation',
            accessor: 'sourceLocation',
            Filter: CheckboxFilter,
            filter: 'includesSome',
          },
          {
            Header: 'Priority Tags',
            id: 'priorityTags',
            accessor: row => row.tags,
            width: 250,
            Cell: ({
              value,
              row,
            }: {
              value: string | null;
              row: Row<DatasetStudy>;
            }) => {
              if (value === null || !row.original.tags) {
                return null;
              }

              return (
                <div className="flex text-xs gap-x-1 font-normal">
                  {row.original.tags.map((tag, i) => (
                    <Tag key={i} tag={tag} />
                  ))}
                </div>
              );
            },
            disableSortBy: true,
            Filter: TextFilter,
            filter: (rows, _columnIds, filterValue) =>
              _.filter(
                rows,
                row =>
                  _.isNil(filterValue) ||
                  _.some(row.original.tags, tags =>
                    tags.name.toLowerCase().includes(filterValue.toLowerCase())
                  )
              ),
          },
          {
            Header: () => {
              return (
                <span
                  title={
                    'Already ordered studies do not count towards your dataset pricing'
                  }
                >
                  Previously Ordered
                </span>
              );
            },
            id: 'previouslyOrdered',
            accessor: 'previouslyOrdered',
            Cell: ({value}) => {
              return (
                <span
                  title={
                    'Already ordered studies do not count towards your dataset pricing'
                  }
                >
                  {value?.valueOf() ? 'Yes' : 'No'}
                </span>
              );
            },
            Filter: TextFilter,
            // Due to the mapping of the UI values, we need to reverse the mapping for the filter to work intuitively
            filter: (rows, _columnIds, filterValue) => {
              if (!filterValue) return rows;
              return rows.filter(row => {
                const val = row.values['previouslyOrdered'];
                if ('yes'.includes(filterValue.toLowerCase())) {
                  return val === true;
                }
                if ('no'.includes(filterValue.toLowerCase())) {
                  return val === false;
                }
                return false; // Exclude row if filter doesn't match 'Yes' or 'No'
              });
            },
            tooltip: 'Previously Ordered',
          },
        ],
      },
      {
        Header: 'Machine',
        columns: [
          {
            Header: 'Manufacturer',
            accessor: 'manufacturer',
            Filter: CheckboxFilter,
            filter: 'includesSome',
          },
          {
            Header: 'Model',
            accessor: 'model',
            Filter: CheckboxFilter,
            filter: 'includesSome',
          },
          {
            Header: 'Site',
            accessor: row => row.site,
            Filter: CheckboxFilter,
            filter: 'includesSome',
          },
        ],
      },
      {
        // hidden column used to perform AND operation between series filters
        id: 'hiddenSeriesFilter',
        filter: (
          rows,
          _columnIds,
          filterValue: {
            seriesDescription?: string;
            sliceThickness?: number[];
            convolutionKernel?: string;
          } = {}
        ) =>
          _.filter(rows, row => {
            return _.some(row.original.metadata?.series, series => {
              // filter seriesDescription
              if (
                !_.isEmpty(filterValue.seriesDescription) &&
                !_.includes(
                  series.seriesDescription.toLowerCase(),
                  filterValue.seriesDescription?.toLowerCase()
                )
              ) {
                return false;
              }

              // filter convonlutionKernel
              if (
                !_.isEmpty(filterValue.convolutionKernel) &&
                !_.includes(
                  series.convolutionKernel.toLowerCase(),
                  filterValue.convolutionKernel?.toLowerCase()
                )
              ) {
                return false;
              }

              // filter sliceThickness
              if (!_.isNil(filterValue.sliceThickness)) {
                if (!_.isNil(filterValue.sliceThickness[0])) {
                  if (series.sliceThickness < filterValue.sliceThickness![0]) {
                    return false;
                  }
                }
                if (!_.isNil(filterValue.sliceThickness[1])) {
                  if (series.sliceThickness > filterValue.sliceThickness![1]) {
                    return false;
                  }
                }
              }

              return true;
            });
          }),
      },
      {
        Header: 'Acquisition',
        columns: [
          {
            id: 'seriesDescription',
            Header: 'Series',
            width: 200,
            accessor: row =>
              _.map(
                row.metadata?.series,
                series => series.seriesDescription
              ).join(', '),
            Cell: ({value}: {value: string; row: Row<DatasetStudy>}) => (
              <span title={value}>{value}</span>
            ),
            Filter: TextFilter,
            filter: 'inclues',
          },
          {
            id: 'sliceThickness',
            Header: 'Slice Thickness',
            width: 200,
            accessor: row =>
              _.map(row.metadata?.series, series => series.sliceThickness).join(
                ', '
              ),
            Cell: ({value}: {value: string; row: Row<DatasetStudy>}) => (
              <span title={value}>{value}</span>
            ),
            Filter: MinMaxFilter,
            filter: (rows, _columnIds, filterValue = []) =>
              _.filter(rows, row => {
                if (!_.isNil(filterValue[0])) {
                  if (
                    !_.some(
                      row.original.metadata?.series,
                      series => series.sliceThickness >= filterValue[0]
                    )
                  ) {
                    return false;
                  }
                }
                if (!_.isNil(filterValue[1])) {
                  if (
                    !_.some(
                      row.original.metadata?.series,
                      series => series.sliceThickness <= filterValue[1]
                    )
                  ) {
                    return false;
                  }
                }
                return true;
              }),
            sortType: (rowA, rowB, _id, desc) => {
              let a, b: number;
              if (!desc) {
                a =
                  _.minBy(rowA.original.metadata?.series, 'sliceThickness')
                    ?.sliceThickness ?? Number.MAX_SAFE_INTEGER;
                b =
                  _.minBy(rowB.original.metadata?.series, 'sliceThickness')
                    ?.sliceThickness ?? Number.MAX_SAFE_INTEGER;
              } else {
                a =
                  _.maxBy(rowA.original.metadata?.series, 'sliceThickness')
                    ?.sliceThickness ?? -Number.MAX_SAFE_INTEGER;
                b =
                  _.maxBy(rowB.original.metadata?.series, 'sliceThickness')
                    ?.sliceThickness ?? -Number.MAX_SAFE_INTEGER;
              }

              if (a > b) {
                return 1;
              }
              if (b > a) {
                return -1;
              }

              return 0;
            },
          },
          {
            id: 'convolutionKernel',
            Header: 'Kernel',
            width: 200,
            accessor: row =>
              _.map(
                row.metadata?.series,
                series => series.convolutionKernel
              ).join(', '),
            Cell: ({value}: {value: string; row: Row<DatasetStudy>}) => (
              <span title={value}>{value}</span>
            ),
            Filter: TextFilter,
            filter: 'inclues',
          },
        ],
      },
      ..._.chain(visibleDicomHeaders)
        .map(header => _.find(dicomHeaders, {header}))
        .compact()
        .groupBy(header => header!.level)
        .toPairs()
        .map(([level, headers]) => ({
          Header: `${level} DICOM headers`,
          columns: headers.map(dicomHeader => {
            let accessor: string | ((row: DatasetStudy) => string) =
              'metadata.dicom_headers_study.' + dicomHeader.header;
            switch (dicomHeader?.level) {
              case 'Study':
                accessor = 'metadata.dicom_headers_study.' + dicomHeader.header;
                break;
              case 'Series':
                accessor = row =>
                  _.chain(row.metadata?.dicom_headers_series)
                    .values()
                    .map(seriesMetadata => seriesMetadata[dicomHeader.header])
                    .compact()
                    .join(', ')
                    .value();
                break;
              case 'Image':
                accessor = row =>
                  _.chain(row.metadata?.dicom_headers_images)
                    .values()
                    .map(seriesMetadata => seriesMetadata[dicomHeader.header])
                    .compact()
                    .map(val =>
                      _.isArray(val)
                        ? `[${val.join(', ')}]`
                        : `[${val.min} - ${val.max}]`
                    )
                    .join(', ')
                    .value();
                break;
            }

            return {
              Header: dicomHeader.description,
              id: dicomHeader.level + '_' + dicomHeader.header,
              accessor: accessor,
              Filter: TextFilter,
              filter: 'inclues',
            };
          }),
        }))
        .value(),
    ],
    [
      datasetId,
      dicomHeaders,
      visibleDicomHeaders,
      datasetDicomInfo,
      internalUser,
    ]
  );
};
