import {useMemo, Fragment, useEffect, useState} from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useMountedLayoutEffect,
  HeaderGroup,
  useResizeColumns,
} from 'react-table';
import {useSticky} from 'react-table-sticky';
import {
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiOutlineX,
  HiSortAscending,
  HiSortDescending,
} from 'react-icons/hi';
import _ from 'lodash';
import {useQuery, useQueryClient} from 'react-query';
import {Link, useNavigate} from 'react-router-dom';
import cx from 'classnames';
import {DatasetStudy, Dataset} from '../../../models/dataset';
import {ColumnDropdown} from '../../../core/components/dropdown';
import {
  StudyTag,
  getMatchedTags,
  Tag as TagInterface,
} from '../../../models/tags';
import {fetchDICOMHeaders} from '../../../models/dicom-header';
import {Pagination} from '../../../core/components/pagination';
import {
  ReportSelectionEventType,
  useReportSelection,
} from '../../../hooks/report-selection-provider';
import {ReportComponent} from '../../../components/report';
import {fetchReport, truncateStudyID} from '../../../models/report';
import {Loading} from '../../../core/components/loading';
import {
  fetchDatasetDicomInfo,
  getDicomSeries,
} from '../../../models/dicom-viewer';
import {useAxios} from 'src/utils/http';
import {isInternal} from 'src/models/auth';
import {useAuth} from 'src/hooks/auth';
import {useMetadataTableColumns} from './metadata-table-columns';

export const MetadataTable = ({
  studies,
  datasets,
  datasetId,
  hideSelect = false,
  hideOrderApproval = false,
  visibleDicomHeaders,
  studyTags,
  userTags,
  className,
  splitView = false,
}: {
  studies: DatasetStudy[];
  datasets?: Dataset[];
  datasetId: number;
  hideSelect?: boolean;
  hideOrderApproval?: boolean;
  visibleDicomHeaders: string[];
  studyTags?: StudyTag[];
  userTags?: TagInterface[];
  className?: string;
  splitView?: boolean;
}) => {
  const {reportSelectionState, reportSelectionDispatch} = useReportSelection();
  const api = useAxios();
  const {authState} = useAuth();
  const internalUser = isInternal(authState.profile!.email);

  const [shiftKeyDown, setShiftKeyDown] = useState(false);
  const [lastClickedIndex, setLastClickedIndex] = useState(-1);
  const [openedStudyID, openedStudyIDChange] = useState<string>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    data: report,
    error: reportError,
    isLoading: reportLoading,
  } = useQuery(
    ['report', openedStudyID],
    () => {
      return fetchReport(api, openedStudyID as string);
    },
    {
      enabled: !_.isEmpty(openedStudyID),
      keepPreviousData: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  useEffect(() => {
    window.addEventListener('keydown', e => {
      setShiftKeyDown(e.shiftKey);
    });
    window.addEventListener('keyup', e => {
      setShiftKeyDown(e.shiftKey);
    });
  }, []);

  const {data: dicomHeaders} = useQuery(
    ['dicomHeaders'],
    () => fetchDICOMHeaders(api),
    {
      staleTime: Infinity,
    }
  );

  const {data: datasetDicomInfo} = useQuery(
    ['datasetDicom', datasetId],
    () => {
      return fetchDatasetDicomInfo(api, datasetId!);
    },
    {
      enabled: !_.isNil(datasetId),
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );

  const stickyColumnIDs = [
    'expand',
    'index',
    'selected',
    'approval',
    'studyId',
    'viewDicom',
  ];

  const columns = useMetadataTableColumns(
    datasetId,
    dicomHeaders,
    visibleDicomHeaders,
    datasetDicomInfo,
    internalUser
  );

  const data = useMemo(
    () =>
      studies.map(study => {
        return {
          ...study,
          tags: getMatchedTags(
            study.studyId,
            studyTags,
            datasets,
            userTags
          ).filter(tag => tag.favorite && tag.tid !== datasetId),
        };
      }),
    [studies, studyTags, datasets, datasetId, userTags]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    page,
    pageCount,
    state: {pageIndex, pageSize, selectedRowIds, filters, hiddenColumns},
    gotoPage,
    toggleAllRowsSelected,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      autoResetExpanded: false,
      disableMultiSort: true,
      disableSortRemove: true,
      initialState: {
        sortBy: [{id: 'approval', desc: false}],
        hiddenColumns: [
          ...(hideSelect ? ['selected'] : []),
          ...(hideOrderApproval ? ['approval'] : []),
          'hiddenSeriesFilter',
        ],
        pageSize: 100,
      },
      getRowId: row => row.studyId,
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      autoResetGroupBy: false,
      autoResetPage: false,
      autoResetRowState: false,
      autoResetSelectedRows: false,
    },
    useFlexLayout,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useSticky,
    useResizeColumns
  );

  useEffect(() => {
    // Update series combination filter
    let seriesFilter:
      | undefined
      | {
          seriesDescription: string;
          convolutionKernel: string;
          sliceThickness: number[];
        } = {
      seriesDescription: _.find(filters, {id: 'seriesDescription'})?.value,
      convolutionKernel: _.find(filters, {id: 'convolutionKernel'})?.value,
      sliceThickness: _.find(filters, {id: 'sliceThickness'})?.value,
    };

    // Don't apply aggregate filter if all filters are empty
    if (!_.some(_.values(seriesFilter), v => v !== undefined)) {
      seriesFilter = undefined;
    }

    if (
      !_.isEqual(
        seriesFilter,
        _.find(filters, {id: 'hiddenSeriesFilter'})?.value
      )
    ) {
      setFilter('hiddenSeriesFilter', seriesFilter);
    }
  }, [filters, setFilter, hiddenColumns]);

  useEffect(() => {
    if (
      _.isNil(reportSelectionState.id) ||
      reportSelectionState.id !== `datasets/${datasetId}`
    ) {
      toggleAllRowsSelected(false);
      reportSelectionDispatch({
        type: ReportSelectionEventType.RESET,
        payload: {id: `datasets/${datasetId}`},
      });
    }
  }, [
    datasetId,
    reportSelectionDispatch,
    reportSelectionState.id,
    toggleAllRowsSelected,
  ]);

  useMountedLayoutEffect(() => {
    if (reportSelectionState.id === `datasets/${datasetId}`) {
      // the diff between selectedRowIds.keys and reportSelectionState.add.ids is what was just marked selected
      // this diff could be either the row the user clicked on or a row auto-selected inside the inner loop below
      const newId = _.find(
        Object.keys(selectedRowIds),
        id => !reportSelectionState.add.ids.has(id)
      );

      // newIndex will reset every time that the inner loop runs but lastClickedIndex will only reset when the user clicks an item
      const newIndex = page.findIndex(row => row.id === newId);
      setLastClickedIndex(newIndex);
      // this block handles selecting any rows in between the row being clicked on and the row that was clicked last
      if (shiftKeyDown && lastClickedIndex > -1) {
        // these bounds determine if we should be looping upward or downward
        const upperBound =
          lastClickedIndex > newIndex ? lastClickedIndex : newIndex;
        const lowerBound =
          lastClickedIndex < newIndex ? lastClickedIndex : newIndex;
        for (let i = lowerBound; i < upperBound; i++) {
          // eslint-disable-next-line security/detect-object-injection
          const row = page[i];
          if (row.isSelected) {
            for (let j = i + 1; j < upperBound; j++) {
              // eslint-disable-next-line security/detect-object-injection
              const laterRow = page[j];
              if (!laterRow.isSelected) {
                laterRow.toggleRowSelected(true);
              }
            }
          }
        }
      }
      reportSelectionDispatch({
        type: ReportSelectionEventType.RESET,
        payload: {
          id: `datasets/${datasetId}`,
          add: {
            ids: new Set(Object.keys(selectedRowIds)),
          },
        },
      });
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (pageIndex > pageCount) {
      gotoPage(0);
    }
  }, [pageIndex, pageCount, gotoPage]);

  const renderHeader = (column: HeaderGroup<DatasetStudy>) => {
    return (
      <>
        {column.render('Header')}
        {column.canSort && (
          <span className="inline-block">
            {column.isSorted &&
              (column.isSortedDesc ? (
                <>
                  {' '}
                  <HiOutlineChevronDown />
                </>
              ) : (
                <>
                  {' '}
                  <HiOutlineChevronUp />
                </>
              ))}
          </span>
        )}
      </>
    );
  };

  return (
    <div className={cx(className)}>
      <div className={cx('bg-white', {splitview: splitView})}>
        <div
          className={cx({
            'splitview-panel-l': splitView,
          })}
        >
          <div className="rounded-lg shadow border border-gray-200">
            <table
              {...getTableProps({
                className: 'min-w-full divide-y divide-gray-200 sticky-table',
              })}
            >
              <thead className="bg-gray-50 divide-y divide-gray-200 header">
                {headerGroups.map((headerGroup, index) => {
                  const {key, ...headerGroupProps} =
                    headerGroup.getHeaderGroupProps();
                  return (
                    <tr
                      data-tour={`metadata-table-header-${index}`}
                      key={key}
                      {...headerGroupProps}
                    >
                      {headerGroup.headers.map(column => {
                        const {key, style, ...headerProps} =
                          column.getHeaderProps({
                            ...column.getResizerProps(),
                            className:
                              'bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
                          });
                        return (
                          <th
                            key={key}
                            style={{
                              ...style,
                              zIndex: ['table_0', ...stickyColumnIDs].includes(
                                column.id
                              )
                                ? 1
                                : style?.zIndex,
                              position: [
                                'table_0',
                                ...stickyColumnIDs,
                              ].includes(column.id)
                                ? 'sticky'
                                : 'relative',
                            }}
                            {...headerProps}
                          >
                            <div className="inline-block relative">
                              {!(column.canSort || column.canFilter) ? (
                                renderHeader(column)
                              ) : (
                                <ColumnDropdown
                                  label={renderHeader(column)}
                                  disabled={(column.columns?.length ?? 0) > 0}
                                >
                                  <div className="text-sm font-normal space-y-2">
                                    {column.canSort && (
                                      <div className="space-y-2">
                                        <button
                                          className={cx(
                                            'text-gray-700 hover:text-gray-800 block',
                                            {
                                              'font-medium text-gray-800':
                                                column.isSorted &&
                                                !column.isSortedDesc,
                                            }
                                          )}
                                          disabled={
                                            column.isSorted &&
                                            !column.isSortedDesc
                                          }
                                          onClick={() =>
                                            column.toggleSortBy(false)
                                          }
                                        >
                                          <HiSortAscending className="text-gray-400 mr-2 inline-block w-5 h-5" />
                                          Sort Ascending (A-Z)
                                        </button>
                                        <button
                                          className={cx(
                                            'text-gray-700 hover:text-gray-800 block',
                                            {
                                              'font-medium text-gray-800':
                                                column.isSorted &&
                                                column.isSortedDesc,
                                            }
                                          )}
                                          disabled={
                                            column.isSorted &&
                                            column.isSortedDesc
                                          }
                                          onClick={() =>
                                            column.toggleSortBy(true)
                                          }
                                        >
                                          <HiSortDescending className="text-gray-400 mr-2 inline-block w-5 h-5" />
                                          Sort Descending (Z-A)
                                        </button>
                                      </div>
                                    )}

                                    {column.filter && column.render('Filter')}
                                  </div>
                                </ColumnDropdown>
                              )}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody
                {...getTableBodyProps({
                  className: 'bg-white divide-y divide-gray-200',
                })}
              >
                {page.map((row, rowIndex) => {
                  prepareRow(row);
                  const {key: rowKey, ...rowProps} = row.getRowProps({
                    className: cx('group'),
                  });
                  return (
                    <Fragment key={rowKey}>
                      <tr {...rowProps}>
                        {row.cells.map((cell, cellIndex) => {
                          const openStudyOnClick =
                            splitView &&
                            ![
                              'expand',
                              'index',
                              'selected',
                              'approval',
                            ].includes(cell.column.id);

                          const {key, ...cellProps} = cell.getCellProps({
                            className: cx(
                              'px-6 py-4 whitespace-nowrap text-sm z-auto',
                              row.original.studyId === openedStudyID
                                ? 'bg-gray-200'
                                : cellIndex < 2
                                ? 'bg-gray-50 text-gray-500'
                                : 'bg-white',
                              {
                                truncate: ![
                                  'index',
                                  'expand',
                                  'selected',
                                  'approval',
                                  'priorityTags',
                                ].includes(cell.column.id),
                                'overflow-x-auto': ['priorityTags'].includes(
                                  cell.column.id
                                ),
                                'cursor-pointer group-hover:text-gray-700':
                                  openStudyOnClick,
                                'group-hover:bg-gray-100': splitView,
                              }
                            ),
                          });

                          return (
                            <td
                              key={key}
                              {...cellProps}
                              title={
                                _.isString(cell.value) ? cell.value : undefined
                              }
                              onClick={() =>
                                openStudyOnClick &&
                                openedStudyIDChange(row.original.studyId)
                              }
                            >
                              {cell.column.id === 'index'
                                ? rowIndex + 1 + pageSize * pageIndex
                                : cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded &&
                        _.map(
                          row.original.metadata!.dicom_headers_series,
                          (seriesHeaders, seriesID) => {
                            const imageHeaders = _.get(
                              row.original.metadata!.dicom_headers_images,
                              seriesID
                            );

                            return (
                              <tr key={`${rowKey}-${seriesID}`} {...rowProps}>
                                {row.cells.map((cell, cellIndex) => {
                                  const simpleSeries = _.find(
                                    row.original.metadata!.series,
                                    {
                                      seriesID: seriesID,
                                    }
                                  );

                                  const colSplit = cell.column.id.split('_');
                                  const header = colSplit[colSplit.length - 1];

                                  const isSeries =
                                    colSplit[colSplit.length - 2] === 'Series';
                                  const isImage =
                                    colSplit[colSplit.length - 2] === 'Image';
                                  let val = '';
                                  if (isSeries) {
                                    val = _.get(seriesHeaders, header);
                                  } else if (isImage) {
                                    const imageVal = _.get(
                                      imageHeaders,
                                      header
                                    );

                                    if (imageVal !== undefined) {
                                      val = _.isArray(imageVal)
                                        ? `[${imageVal.join(', ')}]`
                                        : `[${imageVal.min} - ${imageVal.max}]`;
                                    }
                                  }

                                  const {key, ...cellProps} = cell.getCellProps(
                                    {
                                      className: cx(
                                        'px-6 py-4 whitespace-ellipsis text-sm z-auto truncate',
                                        cellIndex < 2
                                          ? 'bg-gray-50 text-gray-500'
                                          : 'bg-white'
                                      ),
                                    }
                                  );
                                  return (
                                    <td key={key} {...cellProps}>
                                      {cell.column.id === 'seriesDescription' ||
                                      cell.column.id === 'sliceThickness' ||
                                      cell.column.id === 'convolutionKernel'
                                        ? simpleSeries && (
                                            <span
                                              title={`${
                                                simpleSeries[cell.column.id]
                                              }`}
                                            >
                                              {simpleSeries[cell.column.id]}
                                            </span>
                                          )
                                        : val !== '' && (
                                            <span title={val}>{val}</span>
                                          )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          }
                        )}
                    </Fragment>
                  );
                })}
                {rows.length === 0 && (
                  <tr>
                    <td
                      colSpan={visibleColumns.length}
                      className="px-6 py-4 whiespace-nowrap text-sm text-center italic text-gray-400"
                    >
                      No studies found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {openedStudyID && (
          <div
            className={cx({
              'splitview-panel-r xl:border-l-2 xl:border-b-0 border-b-2 border-gray-400':
                splitView,
            })}
          >
            <div>
              <div className="sticky top-0 z-10 border-b border-gray-200 bg-white px-4 py-5 sm:px-6 flex justify-between space-x-2">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  <Link
                    to={`/datasets/${datasetId}/report/${openedStudyID}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary hover:text-primary-active"
                  >
                    {truncateStudyID(openedStudyID)}
                  </Link>
                </h3>
                <button
                  type="button"
                  className={cx('text-gray-400 hover:text-gray-500')}
                  onClick={() => openedStudyIDChange(undefined)}
                >
                  <span className="sr-only">Close</span>

                  <HiOutlineX className="h-6 w-6" />
                </button>
              </div>
              <div className="p-6">
                {report && (
                  <ReportComponent
                    report={report}
                    PHIReported={() => {
                      queryClient.removeQueries(['report', report.studyId]);
                      queryClient.removeQueries(['dataset']);
                      queryClient.removeQueries(['dataset']);
                      queryClient.removeQueries(['search']);
                      navigate({
                        pathname: '/',
                      });
                    }}
                    dicomSeries={getDicomSeries(
                      report.studyId,
                      datasetDicomInfo
                    )}
                    splitViewDataset={true}
                  />
                )}
              </div>
              {!report && (
                <>
                  {reportLoading && <Loading />}
                  {reportError && <div>Error loading report</div>}
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <Pagination
        max={pageCount}
        current={pageIndex + 1}
        linkFunc={num => {
          gotoPage(num - 1);
        }}
      />
    </div>
  );
};
