import {ReactNode} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import cx from 'classnames';

import {Dataset, updateDatasetReports} from '../../models/dataset';
import {
  ReportSelectionState,
  reportSelectionToDatasetUpdateRequest,
} from '../../hooks/report-selection-provider';
import {useAxios} from 'src/utils/http';

export const RemoveFromDatasetButton = ({
  datasetId,
  disabled = false,
  children = 'Remove from dataset',
  removed,
  reportSelectionState,
}: {
  datasetId: number;
  disabled?: boolean;
  children?: ReactNode;
  removed?: (dataset: Dataset) => void;
  reportSelectionState: ReportSelectionState;
}) => {
  const queryClient = useQueryClient();
  const http = useAxios();

  const updateDatasetStudiesMut = useMutation(
    ({
      datasetId,
      studySelectionState,
      operation,
    }: {
      datasetId: number;
      studySelectionState: ReportSelectionState;
      operation: 'add' | 'remove';
    }) =>
      updateDatasetReports(
        http,
        datasetId,
        reportSelectionToDatasetUpdateRequest({
          add: studySelectionState.remove,
          remove: studySelectionState.add,
        }),
        operation
      ),
    {
      onSuccess: result => {
        queryClient.invalidateQueries(['dataset'], {exact: true});
        queryClient.invalidateQueries(['dataset', result.dataset.id]);

        removed && removed(result.dataset);
      },
    }
  );

  return (
    <>
      <button
        type="button"
        disabled={disabled || updateDatasetStudiesMut.isLoading}
        className={cx('btn btn-white py-1.5')}
        onClick={() =>
          updateDatasetStudiesMut.mutate({
            datasetId: datasetId,
            studySelectionState: reportSelectionState,
            operation: 'remove',
          })
        }
      >
        {children}
      </button>
    </>
  );
};
